import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store/index'

const request = function (
    {
        url = '', // 接口路径
        method = 'post', // 请求方式
        params = {}, // get请求时参数实体
        data = {}, // post/put等请求的参数实体
        apiType = 'BASE_URL', // 请求的服务器源
        isLoading = true, // 请求接口时是否显示loading
        toast = true
    }) {
    const service = axios.create({
        baseURL: process.env['VUE_APP_' + apiType] + '/api', // api 的 base_url
        timeout: 60000 // request timeout
    })
    // request interceptor 请求拦截
    service.interceptors.request.use(
        config => {
            if (isLoading) {
                Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    mask: false,
                    message: '加载中...'
                })
            }
            console.log(localStorage.getItem("token"));
            config.headers['token'] = localStorage.getItem("token")
            config.headers['openid'] = localStorage.getItem('openid');
            return config
        },
        error => {
            Toast.clear()
            Promise.reject(error)
        }
    )

    // response interceptor
    service.interceptors.response.use(
        response => {
            Toast.clear()
            const code = response.data.code
            // 请求失败
            if (code === 0) {
                if (toast) {
                    Toast(response.data.msg);
                }
                return Promise.reject(response.data)
            }

            // 登录失效
            if (code === 401) {
                // router.replace('/login')
                return Promise.reject(response.data)
            }

            // 请求成功
            if (code) {
                return response.data
            }
        },
        error => {
            Toast.clear()
            console.log('err' + error) // for debug
            return Promise.reject(error)
        }
    )

    return service({
        url,
        method,
        params,
        data
    })
}

export default request
